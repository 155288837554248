<template>
  <ApplicationTemplate>
    <template v-slot:header>
      <header class="dk-header">
        <div class="dk-header__locales">
          <Locales />
        </div>
      </header>
    </template>
    <template v-slot:default="{classes}">
      <div :class="classes">
        <router-view></router-view>
      </div>
    </template>
  </ApplicationTemplate>
</template>

<script>
  import { ApplicationTemplate } from "auth_app_vue";
  import Locales                 from "auth_app_vue/lib/components/locales";

  export default {
    data() {
      return {};
    },
    computed: {},
    methods: {},
    components: { ApplicationTemplate, Locales },
  }
</script>
