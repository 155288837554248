<template>
  <StaticPage page="cv" class="cv">
    <h1>{{ $t("static.cv.title") }}</h1>
    <div :class="`cv__${part_key}`" :key="part_key" v-for="(part, part_key) in parts">
      <div class="cv-row title">
        <div class="cv-row__when">
        </div><div class="cv-row__what">
          <h2 class="cv-row__what-title">{{ $t(`static.cv.${part_key}.header`) }}</h2>
        </div>
      </div>
      <div class="cv-row" :class="row.state" :key="row_key" v-for="(row, row_key) in part">
        <div class="cv-row__when">
          <span v-if="!row.noperiod" v-html="$t(`static.cv.${part_key}.${row_key}.period`)" />
        </div><div class="cv-row__what">
          <h4 class="cv-row__what-title">{{$t(`static.cv.${part_key}.${row_key}.header`)}}</h4>

          <div class="cv-row__detail">
            <span v-if="!row.values" v-html="$t(`static.cv.${part_key}.${row_key}.detail`)" />
            <p class="cv-row__p" v-html="drawValue(val)" v-else v-for="val in row.values"/>
          </div>
        </div>
      </div>
    </div>
  </StaticPage>
</template>

<script>
  import { StaticPage }       from "auth_app_vue";

  const parts = {
    contacts: {
      name: { noperiod: true },
      dob:  { noperiod: true },
      email: {
        noperiod: true,
        values: [
          { type: "email", target: "mailto:dmitry.klimanov@gmail.com", text: "dmitry.klimanov@gmail.com" }
        ]
      },
      phone: {
        noperiod: true,
        values: [
          { type: "phone", target: "tel:+79190725584", text: "+79190725584" },
          { type: "telegram", target: "tg://resolve?domain=dnmsk", text: "Telegram" },
          { type: "whatsapp", target: "//wa.me/79190725584", text: "WhatsApp", blank: true },
          { type: "skype", target: "skype:dnmskk", text: "Skype" },
        ]
      },
      social: {
        noperiod: true,
        values: [
          { type: "link", link: "//vk.com/dnmsk", text: "VK", blank: true },
          { type: "link", link: "//instagram.com/dnmsk", text: "Instagram", blank: true },
          { type: "link", link: "//github.com/dnmsk", text: "GitHub", blank: true },
        ]
      }
    },
    education: {
      master: { state: "uncompleted" },
      bachelor: { state: "completed" },
      school: { state: "completed" },
      art_school: { state: "completed" },
      sport_school: { state: "completed" }
    },
    work: {
      freelance: {},
      private: {},
      ingate_rails: { state: "completed" },
      ingate_ba: { state: "completed" },
      ingate_net: { state: "completed" },
    },
    details: {
      languages: { noperiod: true },
      professional_exp: { noperiod: true },
      hobbies_prof: { noperiod: true },
      hobbies_sport: { noperiod: true },
      interests: { noperiod: true },
      more: { noperiod: true },
      travels: { noperiod: true, state: "completed" },
    },
  };

  export default {
    components: {
      StaticPage,
    },
    data() {
      return {
        parts
      };
    },
    methods: {
      drawValue(value) {
        switch(value.type) {
          case "link":
            return `<a class="cv-row__link" href="${value.link}" ${value.blank ? "target=_blank" : ""}>${value.text || value.link}</a>`;
          default:
            return `<a class="cv-row__link" href="${value.target}" ${value.blank ? "target=_blank" : ""}>${value.text}</a>`;
        }
      },
    }
  }
</script>
