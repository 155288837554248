import CV       from './pages/cv.vue';
import Landing  from './pages/index.vue';
import LifeGame from './pages/life_game.vue';
import { NotFoundPage } from "auth_app_vue";

export default [
  {
    path: '/',
    name: 'default',
    redirect: to => {
      let locale = Object.keys(window.envs.locales || {})[0];
      return { name: 'index', params: { locale } };
    },
    children: []
  },
  {
    path: '/:locale',
    name: 'index',
    component: Landing
  },
  {
    path: '/:locale/cv',
    name: 'cv',
    component: CV
  },
  {
    path: '/:locale/life_game',
    name: 'life_game',
    component: LifeGame
  },
  {
    path: '/:locale/*',
    name: '404',
    component: NotFoundPage,
  },
  {
    path: '/.*',
    name: '404_',
    component: NotFoundPage,
  },
];
