<template>
  <div>Page is in progress...</div>
</template>

<script>
  import ajax                   from "auth_app_vue";

  export default {
    data() {
      return {};
    },
    computed: {},
    methods: {},
    components: {},
    created,
    destroyed,
  }

  function created() {
  }

  function destroyed() {
  }
</script>
